<template>
<b-row>
  <b-col cols="12" xl="6" class="nopadding">
    <b-card>
      <div slot="header">
        <div class="row">
          <div class="col-md-7">
            <strong># {{$t('common.new_invoice')}}: [{{today}}]</strong>
          </div>
          <div class="col-md-5">
            <strong class="pull-right">
              <i class="fa fa-money 2x" aria-hidden="true"></i> {{formatPrice(total_recal)}} {{$t('common.money_icon')}}</strong>
          </div>
        </div>
      </div>
      <div class="form-group">
        <fieldset class="scheduler-border col-md-12">
          <legend class="scheduler-border">{{$t('common.product_list')}}</legend>
          <div class="row d-flex justify-content-between align-items-center"
          v-for="(itm, k) in $v.invoice.invoice_details.$each.$iter" :key="k" v-bind:class="{'bg-light': k%2==0,'':k%2!=0}">
            <!-- <div class="row"></div> -->
            <div class="form-group col-md-3">
              <label :for="'txt_product_'+k">{{$t('common.product')}}</label>
              <div v-if="$v.$error" class="pull-right">
                <div class="text-danger" v-if="!itm.product_id.required">{{$t('common.product_required')}}</div>
              </div>
              <b-form-select v-model.trim="itm.product_id.$model"
                :id="'ddl_product_'+k" v-on:input="itm.product_id.$touch" @change="onMonChange(k,itm.product_id.$model)">
                <option value="null" disabled>{{$t('common.select_product')}}</option>
                <option v-for="c in ls_product_ddl" :value="c.id"
                v-bind:key="c.id">{{c.name}}</option>
              </b-form-select>
            </div>
            <div class="form-group col-md-2">
              <label :for="'ddl_unit_'+k">{{$t('common.unit')}}</label>
              <div v-if="$v.$error" class="pull-right">
                <div class="text-danger" v-if="!itm.unit_id.required">{{$t('common.unit_required')}}</div>
              </div>
              <b-form-select :id="'ddl_unit_'+k" v-model="itm.unit_id.$model" @change="onDonviChange(k,itm.unit_id.$model)">
                  <option value="null" disabled>{{$t('common.select_unit')}}</option>
                  <option v-for="d in ls_unit_ddl" :value="d.id"
                      v-bind:key="d.id">{{d.name}}</option>
              </b-form-select>
            </div>
            <div class="form-group col-md-2">
              <label :for="'txt_quantity_'+k">{{$t('common.quantity')}}</label>
              <div v-if="$v.$error" class="pull-right">
                <div class="text-danger" v-if="!itm.quantity.required">{{$t('common.quantity_required')}}</div>
                <div class="text-danger" v-else-if="!itm.quantity.numeric">{{$t('common.quantity_number_only')}}</div>
              </div>
              <b-form-input type="number" @change="onQuantityChange(k,itm.quantity.$model)"
                v-model.trim="itm.quantity.$model" class="form-control" size="sm"
                :name="'txt_quantity_'+k" :id="'txt_quantity_'+k"
                maxLength="15"></b-form-input>
            </div>
            <div class="form-group col-md-2">
              <label :for="'txt_price_'+k">{{$t('common.price')}}</label>
              <div v-if="$v.$error" class="pull-right">
                <div class="text-danger" v-if="!itm.price.required">{{$t('common.price_required')}}</div>
              </div>
              <money v-model.trim="itm.price.$model" v-bind="money" class="form-control"
              :name="'txt_price_'+k" :id="'txt_price_'+k" @change.native="onPriceChange(k,itm.price.$model)"
              maxLength="15"></money>
            </div>
            <div class="form-group col-md-2">
              <label :for="'txt_total_'+k">{{$t('common.total')}}</label>
              <div v-if="$v.$error" class="pull-right">
                <div class="text-danger" v-if="!itm.total.required">{{$t('common.total_required')}}</div>
                <div class="text-danger" v-else-if="!itm.total.numeric">{{$t('common.total_number_only')}}</div>
              </div>
              <money v-model.trim="itm.total.$model" v-bind="money"  class="form-control"
              :name="'txt_total_'+k" :id="'txt_total_'+k"
              maxLength="15"></money>
            </div>
            <div class="col-md-1 pull-right text-white">
              <div v-if="invoice.id==null">
                <a class="btn btn-danger mr-1 btn-sm" v-if="invoice.invoice_details.length>1" @click="removeRow(k)"><i class="fa fa-minus" aria-hidden="true"></i></a>
                <a class="btn btn-success btn-sm" @click="addNewRow"><i class="fa fa-plus" aria-hidden="true"></i></a>
              </div>
              <div v-else>
                <a class="btn btn-danger mr-1 btn-sm" v-if="invoice.invoice_details[k].id==null" @click="removeRow(k)"><i class="fa fa-minus" aria-hidden="true"></i></a>
                <a class="btn btn-success btn-sm" @click="addNewRow"><i class="fa fa-plus" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <b-form-group>
        <label for="'txt_des">{{$t('common.description')}}</label>
        <textarea class="form-control" maxlength="300" id="txt_des"
        v-model.trim="invoice.des" :placeholder="$t('common.placeholder_description')"></textarea>
      </b-form-group>
      <b-row>
        <b-col>
            <b-button variant="outline-primary" @click.prevent="save_invoice()"><span class="icon is-small">
                <i class="fa fa-check"></i> {{$t('common.button.save')}} </span></b-button>
          </b-col>
          <b-col>
            <b-button variant="outline-success" @click.prevent="clear()"><span class="icon is-small">
              <i class="fa fa-refresh"></i> {{$t('common.button.refresh')}}</span></b-button>
          </b-col>
      </b-row>
    </b-card>
  </b-col>
  <b-col cols="12" xl="6" class="nopadding">
    <transition name="slide">
      <b-card>
        <div slot="header"><strong><i class="fa fa-list" aria-hidden="true"></i> {{$t('common.room_list')}}</strong></div>
        <b-form-group label-for="table-style-variant" label-cols-lg="2">
          <div class="input-group">
            <input type="text" class="form-control" :placeholder="$t('common.search_text')"
            v-model="filter" maxlenght="50" @keyup.enter="apply_filter(1)">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="apply_filter(1)"
                type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</button>
            </div>
          </div>
        </b-form-group>
        <b-table striped hover responsive
        :items="filteredAndSortedData" :fields="fields" :current-page="page"
        :per-page="0" @row-clicked="rowClicked">
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filteredAndSortedData[field.key]" 
              v-if="field.key && field.label" :placeholder="field.label">
            </td>
          </template>
          <template slot="id" slot-scope="data">
            <label>{{data.item.id}}</label>
          </template>
          <template slot="branch" slot-scope="data">
            <label v-if="data.item.branch">{{data.item.branch.name}}</label>
            <label v-else>{{data.item.company.name}}</label>
          </template>
          <template slot="total" slot-scope="data">
            <label v-if="data.item.total>0">{{formatPrice(data.item.total)}}</label>
            <label v-else>0</label>
          </template>
          <template slot="status" slot-scope="data">
            <b-badge :variant="getBadge(data.item.status)">{{getStatusText(data.item.status)}}</b-badge>
          </template>

          <template slot="created_at" slot-scope="data">
            <label>{{data.item.created_at}}</label>
          </template>
          <template slot="in_bill" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="primary" @click="bill_pdf(data.item.id,80)" v-b-modal.viewModal><i
                  class="fa fa-file-pdf-o"></i> Bill 80</b-button>
              <b-button variant="primary" @click="bill_pdf(data.item.id,5)" v-b-modal.viewModal><i
                  class="fa fa-file-pdf-o"></i> Bill A5</b-button>
            </b-button-group>
          </template>
          <template slot="action" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="outline-danger"
              v-if="data.item.status>-1"
                @click="confirmDelete(data.item)">
                <i class="fa fa-trash"></i> {{$t('common.button.delete')}}
              </b-button>
              <b-button variant="outline-success" v-else
                @click="recoverObj(data.item)">
                <i class="fa fa-recycle"></i> {{$t('common.button.recover')}}
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <template slot="footer">
            <b-row class="pull-right">
              <b-col>
                <nav>
                  <pager :frame="8"
                        :pageCount="page_count"
                        :page="page"
                        @change="change_page">
                  </pager>
                </nav>
              </b-col>
            </b-row>
          </template>
      </b-card>
    </transition>
  </b-col>
  <b-modal id="viewModal" title="Xem thông tin bill" size="lg" header-bg-variant="info" no-body ok-only>
    <div id="invoice_bill_pdf" style="height:450px;"></div>
  </b-modal>
</b-row>
</template>
<style scoped>
  label{
    margin-bottom: 0rem;
  }
  fieldset.scheduler-border {
    border: 1px groove #e4e7ea !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
            box-shadow:  0px 0px 0px 0px #000;
  }

  legend.scheduler-border {
      font-size: 1.2em !important;
      font-weight: bold !important;
      text-align: left !important;
      width:auto;
      padding:0 10px;
      border-bottom:none;
  }
</style>
<script>
  import pdfobj from 'pdfobject'
  import { validationMixin } from 'vuelidate'
  import { required,requiredIf, minLength,maxLength,email,url,numeric } from 'vuelidate/lib/validators'
  import { mapGetters, mapActions } from 'vuex'
  import datePicker from 'vue-bootstrap-datetimepicker'
  import moment from 'moment'
  import Pager from '../Pager'
  import { ModelSelect } from 'vue-search-select'
  import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
  import { Role } from '@/helpers/role.js'
  import { uuid } from 'vue-uuid'

  export default {
    components: { datePicker,ModelSelect,Pager },
    mixins: [validationMixin],
    props: {
      hover: {
        type: Boolean,
        default: true
      }
    },
    data: function () {
      return {
        today:moment().format("DD-MM-YYYY HH:mm:ss"),
        processing:false,
        money: {
          decimal: ',',
          thousands: '.',
          prefix: '$ ',
          precision: 0,
          masked: false
        },
        invoice: {
          id:null,
          invoice_details:[],
          user_create_id:null,
          user_update_id:null,
          total:0,
          company_id:null,
          business:null,
          branch_id:null,
          des:null,
          code:moment().valueOf().toString(),
          type:1
        },
        options: {
          format: 'DD-MM-YYYY',
          useCurrent: true,
          showClear: true,
          showClose: true
        }
      }
    },
    validations: {
      invoice: {
        invoice_details: {
          $each: {
            product_id:{
              required: requiredIf(function () {
                return this.invoice.invoice_details.length>0
              })
            },
            unit_id:{
              required: requiredIf(function () {
                return this.invoice.invoice_details.length>0
              })
            },
            quantity:{
              required: requiredIf(function () {
                return this.invoice.invoice_details.length>0
              }),
              numeric
            },
            total:{
              required: requiredIf(function () {
                return this.invoice.invoice_details.length>0
              }),
              numeric
            },
            price:{
              required: requiredIf(function () {
                return this.invoice.invoice_details.length>0
              }),
              numeric
            }
          }
        }
      }
    },
    computed: {
      ...mapGetters('st_invoice', ['ls_invoice','per_page','filter','page_count','page','type']),
      fields(){
        let fields=[]
        let user=this.$store.state.st_authentication.user
        if(user.role==Role.Super_Admin){
          fields.push({key: 'company',label:this.$t('common.company'),sortable: true, variant:'info'})
          fields.push({key: 'branch',label:this.$t('common.branch'),sortable: true})
          fields.push({key: 'business',label:this.$t('common.business'),sortable: true})
        }

        fields.push({key: 'name',label:this.$t('common.invoice'),sortable: true})

        fields.push({key: 'total',label:this.$t('common.total'),sortable: true, variant:'primary'})
        fields.push({key: 'first_pay',label:this.$t('common.deposit'),sortable: true, variant:'info'})

        fields.push({key: 'discount',label:this.$t('common.discount'),sortable: true, variant:'danger'})

        fields.push({key: 'created_at',label:this.$t('common.created_at'),sortable: true})
        fields.push({key: 'bill',label:this.$t('common.bill'),sortable: false})

        if(user.role==Role.Super_Admin||user.role==Role.Admin||user.role==Role.Branch_Admin){
          fields.push({key: 'action',label:'',sortable: false})
        }

        return fields
      },
      total_recal(){
        debugger
        let temp=_.sumBy(this.invoice.invoice_details.filter(x=>x.status>=0),"total")
        return parseInt(temp)
      },
      ls_mon_ddl(){
        let ls= this.$store.state.st_invoice.ls_mon_ddl
        return ls
      },
      ls_room_ddl(){
        let ls= this.$store.state.st_invoice.ls_room_ddl
        return ls
      },
      ls_product_ddl(){
        let ls= this.$store.state.st_invoice.ls_product_ddl
        return ls
      },
      is_super () {
        let user= this.$store.state.st_authentication.user
        return user.role==Role.Super_Admin
      },
      business () {
        let user= this.$store.state.st_authentication.user
        return user.company.business
      },
      user_login () {
        return this.$store.state.st_authentication.user
      },
      type:{
        get: function(){
          return this.$store.state.st_invoice.type
        },
        set: function(type){
          this.$store.commit('st_invoice/set_type',type)
        }
      },
      filter:{
        get: function(){
            return this.$store.state.st_invoice.filter
        },
        set: function(text){
            this.$store.commit('st_invoice/set_filter',text)
        }
      },
      ls_unit_ddl(){
        return this.$store.state.st_invoice.ls_unit_ddl
      },

      filteredAndSortedData:{
        get: function(){
          let vm=this
          let result = vm.$store.state.st_invoice.ls_invoice
          if (vm.filter) {
            result = result.filter(item =>vm.searchLike(item))
          }

          return result
        }
      }
    },
    methods: {
      ...mapActions('st_invoice', ['get_ls_invoice','apply_filter','change_page']),
      ...mapActions('st_unit', ['get_ls_unit_ddl']),
      ...mapActions('st_product', ['get_ls_product_ddl']),
      bill_pdf(id,type){
        debugger
        let app=this
        var loader = app.$loading.show()
        app.$store.dispatch('st_invoice/bill_pdf',{id,type,business:app.business}).then((response) => {
          if(response && response.data){
            let blob = new Blob([response.data], { type: 'application/pdf' })
            pdfobj.embed(window.URL.createObjectURL(blob), "#invoice_bill_pdf")
          }

          loader.hide()
        })
      },
      onRoomChange(event){
        if(event==null) return
        debugger
        this.invoice.price=this.invoice.total=event.price
      },
      onMonChange(k,event){
        if(event==null) return
        debugger
        k=parseInt(k)
        this.invoice.invoice_details[k].price=event.price
        this.invoice.invoice_details[k].quantity=0
        this.invoice.invoice_details[k].total=0
        this.invoice.invoice_details[k].unit_id=null
      },
      onDonviChange(k,event){
        if(event==null) return
        debugger
        k=parseInt(k)
        this.invoice.invoice_details[k].total=0
        if(this.invoice.invoice_details[k].quantity>0) this.invoice.invoice_details[k].quantity=0
      },
      onPriceChange(k,event){
        debugger
        if(event==null) return
        debugger
        k=parseInt(k)
        if(this.invoice.invoice_details[k].quantity>0){
          this.invoice.invoice_details[k].total=parseInt(this.invoice.invoice_details[k].quantity) * parseInt(this.invoice.invoice_details[k].price)
        }
      },
      onQuantityChange(k,event){
        if(event==null) return
        debugger
        k=parseInt(k)
        if(this.invoice.invoice_details[k].quantity>0){
          this.invoice.invoice_details[k].total=parseInt(this.invoice.invoice_details[k].quantity) * parseInt(this.invoice.invoice_details[k].price)
        }else{
          this.invoice.invoice_details[k].total=0
        }
      },
      addNewRow() {
        this.invoice.invoice_details.push({
          id:null,
          product_id:null,
          price:0,
          total:0,
          unit_id:null,
          quantity:1,
          status:0,
          invoice_id:this.invoice.id||0
        })
      },
      removeRow(k){
        if(k==null || k<0) return
        debugger
        let temp=this.invoice.invoice_details[k]
        if(temp.id==null){
          this.invoice.invoice_details.splice(k, 1)
        }else{
          this.invoice.invoice_details[k].status=-1
        }
      },
      getRowCount (items) {
        return items.length
      },

      rowClicked (item) {
        this.invoice=item
      },

      clear() {
        this.invoice={id:null,branch_id:null,invoice_details:[],des:null,type:1,
        user_create_id:null,user_update_id:null,business:null,code:moment().valueOf().toString(),total:0}
        this.addNewRow()
        this.$v.$reset()
      },
      _validations () {
        return new Promise(resolve => {
          if (this.$v.$error || !this.$v.$pending) {
              return resolve()
          }
          let poll = setInterval(() => {
          if (!this.$v.$pending) {
              clearInterval(poll)
              resolve()
          }
          }, 200)
        })
      },

      async isValid () {
        this.$v.$reset()
        this.$v.$touch()
        await this._validations()
        return Promise.resolve(!this.$v.$error)
      },

      async save_invoice() {
        var app = this
        const isValid = await this.isValid()
        if (!isValid) return
        if (app.processing === true) return

        app.processing = true

        if(app.invoice.id){
          app.invoice.user_update_id=app.user_login.id
          debugger
          app.invoice.total=app.total_recal

          app.$store.dispatch('st_invoice/update',app.invoice)
          .then(function (resp) {
              app.get_ls_invoice(1)
              app.clear()
              app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
              app.processing = false
          }).catch(function (resp) {
            app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
              app.processing = false
          })
        }else{
          app.invoice.user_create_id=app.user_login.id
          app.invoice.business=app.user_login.company.business
          app.invoice.company_id=app.user_login.company_id
          app.invoice.branch_id=app.user_login.branch_id
          debugger
          app.invoice.total=app.total_recal

          app.$store.dispatch('st_invoice/create',app.invoice)
          .then(function (resp) {
              app.get_ls_invoice(1)
              app.clear()
              app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
              app.processing = false
          }).catch(function (resp) {
            app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
              app.processing = false
          })
        }
      },

      async recoverObj(obj){
        let app=this
        if(obj.status>=0) return
        let co=await app.$store.dispatch('st_invoice/recover_obj',obj.id)
        if(co && co.data.ok){
          app.get_ls_invoice(1)
          app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
        }else{
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        }
      },
      confirmDelete(obj) {
        this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
        .then(value => {
          if(value!=true) return
          this.deleteObj(obj)
        })
        .catch(err => {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        })
      },
      async deleteObj(obj) {
        let app=this
        if(obj.status<0) return
        if(obj.status==1) {
          app.$toastr.info(app.$t("common.item_using_message",{obj:app.$t("common.invoice")}),app.$t("common.message"))
          return
        }
        let co=await app.$store.dispatch('st_invoice/delete_obj',obj.id)
        if(co&&co.data.ok){
          app.get_ls_invoice(1)
          app.$toastr.success(app.$t("common.deleted_success"),app.$t("common.message"))
        }else{
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        }
      },

      searchLike:function(item){
        debugger
        return item.created_at.includes(this.filter)
        || item.updated_at&&item.updated_at.includes(this.filter)
        || item.company.name.includes(this.filter)
        || item.branch&&item.branch.name.includes(this.filter)
        || item.total&&item.total.toString().includes(this.filter)
        || item.des&&item.des.includes(this.filter)
      }
    },
    mounted () {
      let vm =this
      vm.type=1
      vm.addNewRow()
      vm.get_ls_invoice(1)
      vm.get_ls_unit_ddl({company_id:vm.user_login.company_id,business:vm.user_login.company.business,lang:vm.$i18n.locale})
    }
  }
</script>
